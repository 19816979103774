<template>
	<div
		class="icon-tag-element"
		:class="['icon-tag-element--color-' + color]"
		:title="title"
	>
		<Icon
			:icon="icon"
		/>
	</div>
</template>

<script>
export default {
	props: {
		// Valid colors: ["orange"]
		color: { type: String, default: "orange" },
		icon: { type: Array, required: true },
		title: { type: String, default: "" }
	}
}
</script>

<style lang="scss">
.icon-tag-element {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  padding: 5px;
  border-radius: 50%;
  width: 19px;
  min-width: 19px;
  height: 19px;
  min-height: 19px;

  &.icon-tag-element--color {
    &-orange {
      background: #c33037;
      color: white;
    }

    &-gray {
      background: #98b8b3;
      color: white;
    }

    &-black {
      background: black;
      color: white;
    }
  }

  svg {
    &.fa-cow {
      font-size: 13px;
    }
  }
}
</style>
