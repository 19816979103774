<template>
	<div>
		<button v-if="showButton" class="scroll-to-top" @click="scrollToTop">
			<Icon
				:icon="['fas', 'angles-up']"
			/>
		</button>
		<div class="scroll-to-top-container"></div>
	</div>
</template>

<script>
export default {
	data () {
		return {
			showButton: false
		}
	},
	mounted () {
		window.addEventListener("scroll", this.handleScroll)
		this.handleScroll()
	},
	beforeUnmount () {
		window.removeEventListener("scroll", this.handleScroll)
	},
	methods: {
		handleScroll () {
			const currentScroll = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
			this.showButton = currentScroll > 500	// Adjust the value to change when the button appears
		},
		scrollToTop () {
			const scrollDuration = 200	// Adjust the value to change the scroll speed
			const scrollStep = -window.scrollY / (scrollDuration / 15)
			const scrollInterval = setInterval(() => {
				if (window.scrollY !== 0) {
					window.scrollBy(0, scrollStep)
				} else {
					clearInterval(scrollInterval)
				}
			}, 15)
		}
	}
}
</script>

<style>
	.scroll-to-top {
		position: fixed;
		bottom: 20px;
		right: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #ffffff;
		/* box-shadow: 0 0 4px #1E1E1E; */
		color: #cc8342;
		border: 2px solid rgb(0 0 0 / 12%);
		border-radius: 5px;
		height: 50px;
		width: 50px;
		font-size: 24px;
		cursor: pointer;
		z-index: 10;
	}

	.scroll-to-top:hover {
		background: #ffcc9f;
		-webkit-transition: all 0.2s ease-in-out;
		-moz-transition: all 0.2s ease-in-out;
		-ms-transition: all 0.2s ease-in-out;
		-o-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;
	}

	.scroll-to-top-container {
		position: fixed !important;
		bottom: 20px !important;
		right: 20px !important;
		height: 80px !important;
		width: 80px !important;
		pointer-events: none !important; /* Ensure that the container doesn't block clicks on underlying elements */
	}
</style>
