const ranking = [
	{
		joint: "Meat Business",
		burger: "Stara Krava"
	},
	{
		joint: "Sorbara Steak House",
		burger: "Burger"
	},
	{
		joint: "Pop's Place",
		burger: "The Boss"
	},
	{
		joint: "As Aperitivo",
		burger: "Boss"
	},
	{
		joint: "Kralj Žara",
		burger: "Dry Aged Holy Cow Burger"
	},
	{
		joint: "Super Hrust",
		burger: "Hrustljavčkov Burger"
	},
	{
		joint: "Tokyo Piknik",
		burger: "Black Angus Burger"
	},
	{
		joint: "Meat Business",
		burger: "Eksotik Burger"
	},
	{
		joint: "Dežela Okusov",
		burger: "Classic Burger"
	},
	{
		joint: "d'Burger",
		burger: "d'Ojaja"
	},
	{
		joint: "Projekt Burger",
		burger: "Popeye"
	},
	{
		joint: "Pop's Place",
		burger: "Slider Duo"
	},
	{
		joint: "Pop's Place",
		burger: "Chicken Sandwich"
	},
	{
		joint: "Lars & Sven",
		burger: "Black Angus Roastbeef Steak"
	},
	{
		joint: "Lars & Sven",
		burger: "Philly Cheese"
	},
	{
		joint: "Lars & Sven",
		burger: "Roastbeef Steak"
	},
	{
		joint: "Hood Burger",
		burger: "The Duke 2.0"
	},
	{
		joint: "Hood Burger",
		burger: "The Duke II"
	},
	{
		joint: "Stari Pisker",
		burger: "Holesterol"
	},
	{
		joint: "Stari Pisker",
		burger: "Novozelandec"
	},
	{
		joint: "Punkt",
		burger: "Chicago"
	},
	{
		joint: "Trappa",
		burger: "Pulled Pork Burger"
	},
	{
		joint: "Projekt Burger",
		burger: "Lord"
	},
	{
		joint: "Wild Brothers",
		burger: "Wild Onion Burger"
	},
	{
		joint: "As Aperitivo",
		burger: "Kobe Beef Sliders"
	},
	{
		joint: "Pop's Place",
		burger: "Bacon Cheese Burger"
	},
	{
		joint: "Pop's Place",
		burger: "Pop's Original Burger"
	},
	{
		joint: "Hood Burger",
		burger: "Classic Burger"
	},
	{
		joint: "Hood Burger",
		burger: "Jake'n'jay"
	},
	{
		joint: "Pop's Place",
		burger: "Original California Classic"
	},
	{
		joint: "Hood Burger",
		burger: "The Duke 2"
	},
	{
		joint: "Trappa",
		burger: "Porchetta Burger"
	},
	{
		joint: "Sorbara Steak House",
		burger: "Burger Foie Gras"
	},
	{
		joint: "Hood Burger",
		burger: "Le Brie"
	},
	{
		joint: "Mini Cafe Golf",
		burger: "Tivoli Pohancek"
	},
	{
		joint: "Wild Brothers",
		burger: "Classic Žanči Burger"
	},
	{
		joint: "Bazen Bar & BBQ",
		burger: "Mejp'l Burger"
	},
	{
		joint: "Kavarna Tiskarna",
		burger: "Pulled Beef"
	},
	{
		joint: "Kavarna Tiskarna",
		burger: "Utrgana Svinja"
	},
	{
		joint: "OPA Resort",
		burger: "Dry Aged Burger"
	},
	{
		joint: "Projekt Burger",
		burger: "Cheez"
	},
	{
		joint: "d'Burger",
		burger: "d'Ojaja"
	},
	{
		joint: "Pri Babici Marici na griču",
		burger: "Kraljevi Krškopoljc"
	},
	{
		joint: "Lars & Sven",
		burger: "Pulled Pork Burger"
	},
	{
		joint: "Tokyo Piknik",
		burger: "Gourmet Burger"
	},
	{
		joint: "Soba 102",
		burger: "Black Angus Bacon Burger"
	},
	{
		joint: "Hood Burger",
		burger: "Chilli Cheese Steak Sandwich"
	},
	{
		joint: "Lars & Sven",
		burger: "Blackadder Burger"
	},
	{
		joint: "Lars & Sven",
		burger: 'Popeye "Spinach" Burger'
	},
	{
		joint: "Mini Cafe Golf",
		burger: "Golf Burger"
	},
	{
		joint: "d'Burger",
		burger: "d'slaninc"
	},
	{
		joint: "Filip's Backyard",
		burger: "Burger"
	},
	{
		joint: "Maister Pub",
		burger: "Maister Burger"
	},
	{
		joint: "Kralj Žara",
		burger: "Pork-a-Madona Pulled Burger"
	},
	{
		joint: "Majmun",
		burger: "Carolina Chopped Pork"
	},
	{
		joint: "Hood Burger",
		burger: "Balbo [2022]"
	},
	{
		joint: "Hood Burger",
		burger: "Big Hood"
	},
	{
		joint: "Pr' Pandi",
		burger: "Brgr"
	},
	{
		joint: "Pr' Pandi",
		burger: "Pandolin"
	},
	{
		joint: "OMAMI Burgers",
		burger: "Smashed Truffle Čiz"
	},
	{
		joint: "Irish Pub",
		burger: "Beef & Bacon Burger"
	},
	{
		joint: "Zbornica",
		burger: "The Principal"
	},
	{
		joint: "Projekt Burger",
		burger: "Bastardo"
	},
	{
		joint: "Soba 102",
		burger: "Black Angus Premium Burger"
	},
	{
		joint: "Americano",
		burger: "Chicken Grill Burger"
	},
	{
		joint: "Fany & Mary",
		burger: "Krpan Bacon Burger"
	},
	{
		joint: "As Aperitivo",
		burger: "California Classic"
	},
	{
		joint: "OMAMI Burgers",
		burger: "Double Smashed Čiz"
	},
	{
		joint: "Ošterija Pr'Noni",
		burger: "Pulled Pork"
	},
	{
		joint: "Zanoodle",
		burger: "Burger"
	},
	{
		joint: "Kavarna Tiskarna",
		burger: "Alora"
	},
	{
		joint: "Pivnica Union",
		burger: "Jezeršek 360° Burger"
	},
	{
		joint: "Fast 5 House",
		burger: "Bacon Burger"
	},
	{
		joint: "Punkt",
		burger: "Dallas"
	},
	{
		joint: "Hood Burger",
		burger: "De La Boca"
	},
	{
		joint: "Pop's Place",
		burger: "Dirty Mac"
	},
	{
		joint: "Halo Pinki",
		burger: "Dule"
	},
	{
		joint: "Punkt",
		burger: "Punkt"
	},
	{
		joint: "Bazen Bar & BBQ",
		burger: "Señor Burger"
	},
	{
		joint: "d'Burger",
		burger: "d'anger"
	},
	{
		joint: "Buldog Bar",
		burger: "Classic Burger"
	},
	{
		joint: "Brunch",
		burger: "The Godfather Burger"
	},
	{
		joint: "Lars & Sven",
		burger: "Magnus Burger"
	},
	{
		joint: "d'Burger",
		burger: "d'šef"
	},
	{
		joint: "Brunch",
		burger: "The Dark Vader Spicy Burger"
	},
	{
		joint: "Buldog Bar",
		burger: "Bulldog Burger"
	},
	{
		joint: "Hood Burger",
		burger: "Billy Bob Bacon"
	},
	{
		joint: "Lars & Sven",
		burger: "Rookie Burger"
	},
	{
		joint: "Forum",
		burger: "4UM Burger [Mk. I]"
	},
	{
		joint: "Lars & Sven",
		burger: "Crispy Chicken Burger"
	},
	{
		joint: "Jurman Food Corner",
		burger: "Big Boss Burger"
	},
	{
		joint: "Kavarna Tiskarna",
		burger: "Pohanc"
	},
	{
		joint: "Lars & Sven",
		burger: "Sven Burger"
	},
	{
		joint: "Biljardnica Direkt",
		burger: "Klasik Burger"
	},
	{
		joint: "Stari Pisker",
		burger: "Pierre"
	},
	{
		joint: "Texas Bbq Ljubljana",
		burger: "Vič King Burger"
	},
	{
		joint: "Mini Cafe Golf",
		burger: "Utrganc BBQ"
	},
	{
		joint: "Dvorni Bar",
		burger: "Classic Burger"
	},
	{
		joint: "Wild Brothers",
		burger: "Sick Burger"
	},
	{
		joint: "Zbornica",
		burger: "Smokey Burger"
	},
	{
		joint: "Kavarna Tiskarna",
		burger: "Tiskarna"
	},
	{
		joint: "Hood Burger",
		burger: "Chicken Burger"
	},
	{
		joint: "Fany & Mary",
		burger: "Classic Burger"
	},
	{
		joint: "Hood Burger",
		burger: "Balbo"
	},
	{
		joint: "Kralj Žara",
		burger: "Holy Cow Burger"
	},
	{
		joint: "Americano",
		burger: "Chicken Crispy Burger"
	},
	{
		joint: "d'Burger",
		burger: "d'klasik"
	},
	{
		joint: "Hood Burger",
		burger: "Hood Steak"
	},
	{
		joint: "Hood Burger",
		burger: "Louis Lunch"
	},
	{
		joint: "Birdie Foodielab",
		burger: "Čiz Smash"
	},
	{
		joint: "Hood Burger",
		burger: "Fatboy Slim"
	},
	{
		joint: "Dežela Okusov",
		burger: "Open-Face Angus Burger"
	},
	{
		joint: "Fany & Mary",
		burger: "Tartuf Burger"
	},
	{
		joint: "Fany & Mary",
		burger: "Flying Chicken Burger"
	},
	{
		joint: "Forum",
		burger: "Smokey Burger [Mk. I]"
	},
	{
		joint: "Ošterija Pr'noni",
		burger: "Beef Burger"
	},
	{
		joint: "Hot Horse",
		burger: "Horseburger"
	},
	{
		joint: "Hood Burger",
		burger: "Lady Marmalade"
	},
	{
		joint: "Mangoop Burgers",
		burger: "Mangoop Burger"
	},
	{
		joint: "Hood Burger",
		burger: "John Goat"
	},
	{
		joint: "Mini Cafe Golf",
		burger: "Fit Burger"
	},
	{
		joint: "Dežela Okusov",
		burger: "Fried Chicken Burger"
	},
	{
		joint: "Raca Gastrobar",
		burger: "American Classic"
	},
	{
		joint: "7 Burger",
		burger: "Painkiller"
	},
	{
		joint: "Kavarna Tiskarna",
		burger: "Dirty Sanchez [Mk. I]"
	},
	{
		joint: "Gostilnica in Pivnica Vič",
		burger: "Prestiž"
	},
	{
		joint: "Mangoop Burgers",
		burger: "Beerger"
	},
	{
		joint: "Punkt",
		burger: "Kraški"
	},
	{
		joint: "Mangoop Burgers",
		burger: "Cosa Nostra Burger"
	},
	{
		joint: "Pri Babici Marici na griču",
		burger: "Ftrgan Zajc"
	},
	{
		joint: "Hotspot Bar & Bistro",
		burger: "Bacon Burger"
	},
	{
		joint: "Lars & Sven",
		burger: "Philly Chilly"
	},
	{
		joint: "Stari Pisker",
		burger: "Mac 'N' Cheese"
	},
	{
		joint: "Gostilnica in Pivnica Šiška",
		burger: "Gurman"
	},
	{
		joint: "Dnevni Bar Pr' Mrtinet",
		burger: "Pulled Beef Burger"
	},
	{
		joint: "Pri Babici Marici na griču",
		burger: "Goveji Bejkn"
	},
	{
		joint: "Stazione Parenzana Ljubljana",
		burger: "Beef burger with pancetta and cheddar cheese"
	},
	{
		joint: "Vija Vaja",
		burger: "Cheeseburger"
	},
	{
		joint: "Stara Fabrka",
		burger: "Direktorski"
	},
	{
		joint: "Pop's Place",
		burger: "Silverton Burger"
	},
	{
		joint: "Kubus - Gostilna in Pizzerija",
		burger: "Kubus Burger"
	},
	{
		joint: "Harat's Pub",
		burger: "McGreggor Burger"
	},
	{
		joint: "Hood Burger",
		burger: "Hood Chick Sandwich"
	},
	{
		joint: "Hood Burger",
		burger: "The Kliffhanger"
	},
	{
		joint: "Kuhinja Krušič",
		burger: "Krušič Burger"
	},
	{
		joint: "Lars & Sven",
		burger: "Lars Burger"
	},
	{
		joint: "Americano",
		burger: "Bacon Burger"
	},
	{
		joint: "Dežela Okusov",
		burger: "Grilled Chicken Burger"
	},
	{
		joint: "Soba 102",
		burger: "Chicken Burger"
	},
	{
		joint: "Hotspot Bar & Bistro",
		burger: "Original Burger"
	},
	{
		joint: "Oaza Pef",
		burger: "Beef Burger"
	},
	{
		joint: "Azur Tratorija",
		burger: "Angus Burger"
	},
	{
		joint: "Boschitz",
		burger: "Burger S Slovensko Govedino"
	},
	{
		joint: "Ajda Premium Burger",
		burger: "Premium Burger"
	},
	{
		joint: "7 Burger",
		burger: "Mind Blower"
	},
	{
		joint: "Rex Burger Pivo Sport",
		burger: "Razza Piemontese"
	},
	{
		joint: "Forum",
		burger: "4UM Burger"
	},
	{
		joint: "Forum",
		burger: "Mr. Big"
	},
	{
		joint: "7 Burger",
		burger: "7 Burger"
	},
	{
		joint: "FNX Café & Bistro",
		burger: "Angus Burger"
	},
	{
		joint: "Kavarna Rog",
		burger: "Rog Burger"
	},
	{
		joint: "Burger Time",
		burger: "Speedy Burger"
	},
	{
		joint: "Hillbilly's",
		burger: "Hillbilly"
	},
	{
		joint: "Cube Burger",
		burger: "Kraljevi Burger XXL"
	},
	{
		joint: "Cube Burger",
		burger: "Boss Burger"
	},
	{
		joint: "Cube Burger",
		burger: "Cube Burger"
	},
	{
		joint: "Jack & Joe Steak and Burger Club",
		burger: "Angus"
	},
	{
		joint: "d'Burger",
		burger: "d'pohanc"
	},
	{
		joint: "Kavarna Tiskarna",
		burger: "Tiskarna [Mk. I]"
	},
	{
		joint: "Stari Pisker",
		burger: "Umami"
	},
	{
		joint: "Tabay Brothers",
		burger: "Metropolitan"
	},
	{
		joint: "Texas Bbq Ljubljana",
		burger: "Beef Brisket"
	},
	{
		joint: "Tink Superfood Café",
		burger: "Čiz Klasik"
	},
	{
		joint: "Bazen Bar & BBQ",
		burger: "Klasik Burger"
	},
	{
		joint: "Urban Piqniq",
		burger: "Burger z Istrijanom"
	},
	{
		joint: "Super Pšanc",
		burger: "Šefov Burger"
	},
	{
		joint: "Burek Olimpija Šiška",
		burger: "Cheeseburger"
	},
	{
		joint: "Burek Olimpija Šiška",
		burger: "Beef Burger"
	},
	{
		joint: "Hood Burger",
		burger: "The Duke"
	},
	{
		joint: "Pop's Place",
		burger: "The Gotham Burger"
	},
	{
		joint: "Biljardnica Direkt",
		burger: "Chicken Burger"
	},
	{
		joint: "Frks",
		burger: "Frks Burger"
	},
	{
		joint: "Lars & Sven",
		burger: "Smokey Burger"
	},
	{
		joint: "Le Petit Cafe",
		burger: "Le Petit Burger"
	},
	{
		joint: "Rex Burger Pivo Sport",
		burger: "Rex Burger"
	},
	{
		joint: "Majmun",
		burger: "Crispy Cheeseburger"
	},
	{
		joint: "Lars & Sven",
		burger: "Chicken Steak"
	},
	{
		joint: "Jack & Joe Steak and Burger Club",
		burger: "Jack the Tartuf"
	},
	{
		joint: "Kavarna Tiskarna",
		burger: "Classique Cheese [Mk. I]"
	},
	{
		joint: "Super Hrust",
		burger: "Pulled Lamb"
	},
	{
		joint: "Bežigrajski Dvor",
		burger: "Smokey Burger"
	},
	{
		joint: "Zbornica",
		burger: "Professor Luigi Burger"
	},
	{
		joint: "Biljardna Hiša Ljubljana",
		burger: "Bilijarder"
	},
	{
		joint: "GourmeThomas",
		burger: "100% Beef Burger"
	},
	{
		joint: "Hiša Pod Gradom",
		burger: "Hiša Pod Gradom"
	},
	{
		joint: "Hood Burger",
		burger: "Der Würstmeister"
	},
	{
		joint: "Fany & Mary",
		burger: "Country Burger"
	},
	{
		joint: "Kastrola",
		burger: "Texas Burger"
	},
	{
		joint: "Trappa",
		burger: "Shrimp Burger"
	},
	{
		joint: "Pišek Bar",
		burger: "Logist Burger"
	},
	{
		joint: "Hood Burger",
		burger: "The Cuban Sandwich"
	},
	{
		joint: "Cube Burger",
		burger: "Roastbeef Sandwich"
	},
	{
		joint: "Forum",
		burger: "Chicken Burger"
	},
	{
		joint: "Gastro House 151",
		burger: "Gastro Burger"
	},
	{
		joint: "Lor&Di",
		burger: "Texas Burger"
	},
	{
		joint: "Park Žibert Drive-in",
		burger: "Taprav"
	},
	{
		joint: "TINK Superfood Cafe",
		burger: "Double Cheeseburger"
	},
	{
		joint: "Hood Burger",
		burger: "Hood Kahuna Burger"
	},
	{
		joint: "Stazione Parenzana Ljubljana",
		burger: "Crispy chicken burger"
	},
	{
		joint: "Romansa 1971",
		burger: "Republika Burger"
	},
	{
		joint: "Kavarna Tiskarna",
		burger: "Metropolitan'c [Mk. I]"
	},
	{
		joint: "Kavarna Tiskarna",
		burger: "Johnny [Mk. I]"
	},
	{
		joint: "Top Chef",
		burger: "Bigboy"
	},
	{
		joint: "Top Chef",
		burger: "Topchef"
	},
	{
		joint: "Stara Fabrka",
		burger: "Tretja Smena"
	},
	{
		joint: "Halo Podnevi & Ponoči",
		burger: "Cheeseburger"
	},
	{
		joint: "Romansa 1971",
		burger: "Cankarjev Burger"
	},
	{
		joint: "Biljardna Hiša Ljubljana",
		burger: "Trgana Svinjina"
	},
	{
		joint: "Svetilnik",
		burger: "Cheeseburger"
	},
	{
		joint: "TINK Superfood Cafe",
		burger: "Tartuf Burger"
	},
	{
		joint: "Kino Bežigrad",
		burger: "Fat Billy"
	},
	{
		joint: "Stazione Parenzana Ljubljana",
		burger: "Burger with beef, brie cheese and aspargus"
	},
	{
		joint: "Romansa 1971",
		burger: "Maxim Burger"
	},
	{
		joint: "Pošta Slovenije",
		burger: "Burger"
	},
	{
		joint: "Svetilnik",
		burger: "Chicken Burger"
	},
	{
		joint: "Allegro Bistro & Lounge Bar",
		burger: "Burger Bacon"
	}
]

export default ranking
