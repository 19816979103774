var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"burgers-page"},[_vm._m(0),_c('div',{staticClass:"burgers-page__search-container",class:{
			'burgers-page__search-container--active': !!_vm.searchString
		}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchString),expression:"searchString"}],staticClass:"burgers-page__search-input",attrs:{"type":"text","placeholder":"Search for a burger or joint"},domProps:{"value":(_vm.searchString)},on:{"input":function($event){if($event.target.composing)return;_vm.searchString=$event.target.value}}}),(!_vm.searchString)?_c('Icon',{staticClass:"burgers-page__search-icon-magnifying-glass",attrs:{"icon":['fas', 'magnifying-glass']}}):_vm._e(),(_vm.searchString)?_c('button',{staticClass:"burgers-page__search-button-clear",on:{"click":function($event){return _vm.clearSearch()}}},[_c('Icon',{staticClass:"burgers-page__search-icon-clear",attrs:{"icon":['fas', 'xmark']}})],1):_vm._e()],1),_c('div',{staticClass:"burgers-page__table-wrapper"},[_c('div',{staticClass:"burgers-page__table",attrs:{"cellspacing":"0"}},[_c('tbody',[_c('div',{staticClass:"brugers-page__table-row-group-rating-cover"}),_vm._l((_vm.groupedData),function(group){return [_c('BurgerScore',{key:'group-key-' + group.key,staticClass:"burgers-page__table-row-group-rating",attrs:{"score":group.key}}),_vm._l((group.burgers),function(burger,key){return _c('router-link',{key:group.key + '-' + key,staticClass:"burgers-page__table-row",class:{'burgers-page__table-row--discontinued': burger.tags.includes('discontinued')},attrs:{"to":{
							name: 'BurgerDetails',
							params: {
								joint: _vm.slugify(burger.joint.title),
								burger: _vm.slugify(burger.title)
							}
						}}},[_c('div',{staticClass:"burgers-page__table-cell burgers-page__table-meat-icon__container--mobile"},_vm._l((burger.meat_types.filter(element => !!element)),function(meat,key){return _c('Icon',{key:key,staticClass:"burgers-page__table-meat-icon",attrs:{"icon":['fac', meat]}})}),1),_c('td',{staticClass:"burgers-page__table-cell burgers-page__table-cell-burger"},[_c('div',{staticClass:"burgers-page__table-cell-burger-subcontainer"},[_c('span',{staticClass:"burgers-page__table-cell-burger-name"},[_c('span',[_vm._v(_vm._s(burger.title))])]),_c('router-link',{staticClass:"burgers-page__table-cell-burger-joint-name",attrs:{"to":{
										name: 'JointDetails',
										params: {
											joint: _vm.slugify(burger.joint.title)
										}
									}},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" "+_vm._s(burger.joint.title)+" ")])],1)]),_c('td',{staticClass:"burgers-page__table-cell burgers-page__table-cell-mini-review"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getMiniReview(burger))}})]),_c('td',{staticClass:"burgers-page__table-cell burgers-page__table-cell-tags"},[_c('div',{staticClass:"burgers-page__tag-container"},[(burger.tags && burger.tags.includes('discontinued'))?_c('IconTag',{attrs:{"color":"gray","title":"Discontinued","icon":['fas', 'heart-crack']}}):_vm._e(),(burger.tags && burger.tags.includes('special-edition'))?_c('IconTag',{attrs:{"color":"orange","title":"Special Edition","icon":['fas', 'stopwatch']}}):_vm._e()],1)])])})]})],2),(_vm.groupedData.length === 0)?_c('tr',{staticClass:"burgers-page__table-row-not-found"},[_c('td',{staticClass:"burgers-page__table-row-not-found__text",attrs:{"colspan":"99"}},[_vm._v("No results. Try searching for something else.")])]):_vm._e()])]),_c('ScrollToTop')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',[_vm._v("Ranking")])])
}]

export { render, staticRenderFns }