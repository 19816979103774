<template>
	<div class="burgers-page">
		<div>
			<h1>Ranking</h1>
		</div>
		<div
			class="burgers-page__search-container"
			:class="{
				'burgers-page__search-container--active': !!searchString
			}"
		>
			<input
				v-model="searchString"
				type="text"
				class="burgers-page__search-input"
				placeholder="Search for a burger or joint"
			/>
			<Icon
				v-if="!searchString"
				:icon="['fas', 'magnifying-glass']"
				class="burgers-page__search-icon-magnifying-glass"
			/>
			<button
				v-if="searchString"
				class="burgers-page__search-button-clear"
				@click="clearSearch()"
			>
				<Icon
					:icon="['fas', 'xmark']"
					class="burgers-page__search-icon-clear"
				/>
			</button>
		</div>
		<div class="burgers-page__table-wrapper">
			<div class="burgers-page__table" cellspacing="0">
				<tbody>
					<div class="brugers-page__table-row-group-rating-cover" />
					<template v-for="group in groupedData">
						<BurgerScore
							:key="'group-key-' + group.key"
							class="burgers-page__table-row-group-rating"
							:score="group.key"
						/>
						<router-link
							v-for="(burger, key) in group.burgers"
							:key="group.key + '-' + key"
							class="burgers-page__table-row"
							:class="{'burgers-page__table-row--discontinued': burger.tags.includes('discontinued')}"
							:to="{
								name: 'BurgerDetails',
								params: {
									joint: slugify(burger.joint.title),
									burger: slugify(burger.title)
								}
							}"
						>
							<div class="burgers-page__table-cell burgers-page__table-meat-icon__container--mobile">
								<Icon
									v-for="(meat, key) in burger.meat_types.filter(element => !!element)"
									:key="key"
									class="burgers-page__table-meat-icon"
									:icon="['fac', meat]"
								/>
							</div>
							<td class="burgers-page__table-cell burgers-page__table-cell-burger">
								<div class="burgers-page__table-cell-burger-subcontainer">
									<span class="burgers-page__table-cell-burger-name">
										<span>{{ burger.title }}</span>
									</span>
									<router-link
										class="burgers-page__table-cell-burger-joint-name"
										:to="{
											name: 'JointDetails',
											params: {
												joint: slugify(burger.joint.title)
											}
										}"
										@click.native.stop
									>
										{{ burger.joint.title }}
									</router-link>
								</div>
							</td>
							<td class="burgers-page__table-cell burgers-page__table-cell-mini-review">
								<span v-html="getMiniReview(burger)"/>
							</td>
							<td class="burgers-page__table-cell burgers-page__table-cell-tags">
								<div class="burgers-page__tag-container">
									<IconTag
										v-if="burger.tags && burger.tags.includes('discontinued')"
										color="gray"
										title="Discontinued"
										:icon="['fas', 'heart-crack']"
									/>
									<IconTag
										v-if="burger.tags && burger.tags.includes('special-edition')"
										color="orange"
										title="Special Edition"
										:icon="['fas', 'stopwatch']"
									/>
								</div>
							</td>
						</router-link>
					</template>
				</tbody>
				<tr v-if="groupedData.length === 0" class="burgers-page__table-row-not-found">
					<td colspan="99" class="burgers-page__table-row-not-found__text">No results. Try searching for something else.</td>
				</tr>
			</div>
		</div>
		<ScrollToTop />
	</div>
</template>

<script>
import BurgerScore from "@/components/BurgerScore.vue"
import ScrollToTop from "@/components/ScrollToTop.vue"
import IconTag from "@/components/IconTag.vue"
import Burgers from "@/assets/Burgers/Joints.js"
import Ranking from "@/assets/Burgers/Ranking.js"
import Slugify from "@/mixins/Slugify.js"

export default {
	components: {
		IconTag,
		BurgerScore,
		ScrollToTop
	},
	mixins: [
		Slugify
	],
	data () {
		return {
			Burgers,
			searchString: ""
		}
	},
	computed: {
		groupedData () {
			const output = []
			Ranking.forEach(burger => {
				const jointSlug = this.slugify(burger.joint)
				const burgerSlug = this.slugify(burger.burger)
				if (!this.$store.getters.JointsAndBurgersBySlug[jointSlug]) {
					// eslint-disable-next-line no-console
					console.warn("Missing Joint: " + burger.joint)
				}
				const joint = this.$store.getters.JointsAndBurgersBySlug[jointSlug]
				if (!joint.burgers[burgerSlug]) {
					// eslint-disable-next-line no-console
					console.warn("Missing Burger: " + burger.burger)
				}
				const outputBurger = this.$store.getters.JointsAndBurgersBySlug[jointSlug].burgers[burgerSlug]
				let group = output.find(group => group.key === outputBurger.rating)
				if (!group) {
					group = {
						key: outputBurger.rating,
						burgers: []
					}
					output.push(group)
				}
				group.burgers.push({ ...outputBurger, joint })
			})
			if (!this.searchString) return output
			const searchWords = this.searchString.split(" ")
			searchWords.forEach(word => {
				for (const groupKey in Object.keys(output)) {
					if (!output[groupKey] || !Array.isArray(output[groupKey].burgers)) continue
					output[groupKey].burgers = output[groupKey].burgers.filter(burger => {
						const lowerCaseWord = word.toLowerCase()
						return burger.title.toLowerCase().includes(lowerCaseWord) || burger.joint.title.toLowerCase().includes(lowerCaseWord)
					})
				}
			})
			return output.filter(group => group.burgers.length > 0)
		}
	},
	methods: {
		getMiniReview (burger) {
			const reviewIndex = burger.sections.findIndex(section => section.title === "Review")
			if (reviewIndex === -1) return ""
			return burger.sections[reviewIndex].lede
		},
		clearSearch () {
			this.searchString = ""
		}
	}
}
</script>

<style lang="scss">
	@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,600;1,400;1,600&display=swap');
	@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

	.burgers-page {
		background: #FAF1E4;
		padding: 64px 0;
		display: flex;
		justify-content: flex-start;
		flex-direction: column;
		align-items: center;

		h1 {
			color: #000;
			font-size: 38px;
			font-family: "Open Sans", sans-serif;
			font-weight: 700;
			letter-spacing: 1px;
			line-height: 1.4;
			margin-bottom: 30px;
			margin-top: 20px;
			text-align: center;
			text-transform: uppercase;
		}

		.burgers-page__search-container {
			width: calc(1000px * 0.6);
			margin-bottom: 10px;
			position: relative;
			max-width: 90%;

			&:focus-within,
			&.burgers-page__search-container--active {
				.burgers-page__search-input {
					border-color: #2c3e50;
				}

				.burgers-page__search-icon-magnifying-glass {
					color: #2c3e50;
				}

				.burgers-page__search-button-clear {
					color: #2c3e50;
				}
			}

			.burgers-page__search-input {
				width: 100%;
				padding: 10px 15px;
				font-size: 18px;
				border: solid 1px #d2d2d2;
				outline: none;
				border-radius: 5px;
				box-sizing: border-box;
			}

			.burgers-page__search-icon-magnifying-glass {
				position: absolute;
				top: 13px;
				right: 13px;
				color: #d2d2d2;
				font-size: 16px;
			}

			.burgers-page__search-button-clear {
				position: absolute;
				top: 6px;
				right: 6px;
				color: #d2d2d2;
				font-size: 20px;
				border: none;
				background: transparent;
				outline: none;
				border-radius: 50%;
				padding: 4px;
				width: 31px;
				height: 31px;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;

				&:hover {
					background: rgba(#d2d2d2, 0.5);
				}
			}
		}

	.burgers-page__table-wrapper{
		background: white;
		border-radius: 4px;
		max-width: 100%;
		width: 100%;
		background: #faf1e4;
		display: flex;
		flex-direction: row;
		justify-content: center;

			.burgers-page__table {
				width: 1000px;
				border-collapse: separate;
				border-spacing: 0 5px;
				display: table;
				max-width: 100%;
				display: block;
				padding-bottom: 64px;

				tbody {
					border-radius: 4px;
					position: relative;
					max-width: 100%;
					display: flex;
					flex-direction: column;
					gap: 10px;
					align-items: center;
				}

				.brugers-page__table-row-group-rating-cover {
					position: sticky;
					top: 86px;
					height: 15px;
					width: 100%;
					background: #faf1e4;
					z-index: 1;
				}

				.burgers-page__table-row-group-rating {
					position: sticky;
					top: 101px;
					z-index: 1;
					display: flex;
					background: #ffdcbe;
					padding: 14px;
					border-radius: 5px;
					justify-content: flex-start;
					align-items: center;
					margin: 40px 0 7px;
					padding-left: 30px;
					width: 100%;
					height: 80px;
					max-width: 940px;
					opacity: 1;
					padding-left: 22px;
					box-sizing: border-box;
					border: 2px solid rgb(0 0 0 / 12%);

					@media only screen and (max-width: 950px) {
						border-radius: 0;
						border-left: none;
						border-right: none;
						top: 86px;
						max-width: initial;
					}

					img {
						height: 35px;
					}

					&:first-of-type {
						box-shadow: 0px 4px 4px #0000000f;
						margin-top: 0;
					}
				}

				.burgers-page__table-row {
					border-radius: 5px;
					// box-shadow: 0 0 13px #0000001f;
					border: 2px solid rgb(0 0 0 / 12%);
					background-color: #ffffff;
					display: grid;
					padding: 10px;
					grid-template-rows: auto auto;
					grid-template-columns: 60px calc(45% - 70px) calc(55% - 70px) 80px;
					grid-template-areas:
						"meat burger review tags"
						"meat burger review tags"
					;
					width: 90%;

					@media only screen and (max-width: 767px) {
						grid-template-rows: auto auto;
						grid-template-columns: 50px calc(100% - 100px) 50px;
						grid-template-areas:
							"meat burger tags"
							"meat joint tags"
						;
					}

					&--discontinued {
						.burgers-page__table-cell {
							opacity: 0.4;
							&.burgers-page__table-cell-score {
								opacity: 0.5;
							}

							&.burgers-page__table-cell-tags {
								opacity: 1;
							}
						}

						&:hover {
							.burgers-page__table-cell-score,
							.burgers-page__table-cell-burger,
							.burgers-page__table-cell-mini-review {
								opacity: 1;
							}
						}
					}

					&:hover {
						background: #ffdcbe;
						-webkit-transition: all 0.1s ease-in-out;
						-moz-transition: all 0.1s ease-in-out;
						-ms-transition: all 0.1s ease-in-out;
						-o-transition: all 0.1s ease-in-out;
						transition: all 0.1s ease-in-out;

						.burgers-page__table-cell {

							&.burgers-page__table-cell-burger {
								// color: #2c3e50 !important;

								.burgers-page__table-meat-icon {
									color: #2c3e50 !important;
								}

								// .burgers-page__table-cell-burger-name,
								// .burgers-page__table-cell-burger-joint-name {
								// 	color: #2c3e50 !important;
								// }
							}

							&.burgers-page__table-cell-mini-review {
								color: #2c3e50 !important;
							}
						}
					}

					.burgers-page__table-cell {
						padding: 15px 10px;
						color: #cc8342;
						font-size: 20px;
						display: table-cell;
						vertical-align: middle;
						display: table-cell;
						box-sizing: border-box;

						&:last-child {
							border-top-right-radius: 5px;
							border-bottom-right-radius: 5px;
						}

						&:first-child {
							border-top-left-radius: 5px;
							border-bottom-left-radius: 5px;
						}

						&.burgers-page__table-meat-icon__container--mobile {
							grid-area: meat;
							align-items: center;
							justify-content: center;
							margin-right: 5px;
							margin-left: 0;
							width: 35px;
							height: 35px;
							align-self: center;
							justify-self: center;
							display: flex;

							.burgers-page__table-meat-icon {
								opacity: 0.9;
								color: #2c3e50;
								font-size: 15px;
							}
						}

						&.burgers-page__table-cell-burger {
							grid-area: burger;
							font-size: 18px;
							font-weight: 600;
							align-self: center;
							padding: 5px;
							white-space: normal;

							.burgers-page__table-cell-burger-subcontainer {
								display: flex;
								flex-direction: column;
								align-items: flex-start;
								justify-content: flex-start;

								.burgers-page__table-cell-burger-name {
									display: flex;
									align-items: center;
									justify-content: center;
								}

								.burgers-page__table-cell-burger-joint-name {
									font-size: 16px;
									font-weight: 400;
									color: #646b71;
									white-space: normal;
									width: fit-content;
									padding: 3px 1px 0;

									&:hover {
										text-decoration: underline;
										color: #cc8342;
									}
								}
							}
						}

						&.burgers-page__table-cell-mini-review {
							grid-area: review;
							width: 99%;
							font-size: 15px;
							padding-top: 10px;
							padding-bottom: 10px;
							vertical-align: middle;
							color: #474747;
							display: flex;
							flex-direction: row;
							align-items: center;

							@media only screen and (max-width: 767px) {
								display: none;
							}
						}

						&.burgers-page__table-cell-tags {
							grid-area: tags;
							display: flex;
							align-items: center;
							padding: 0 15px;

							.burgers-page__tag-container {
								display: flex;
								justify-content: center;
								width: 100%;
								flex-direction: row;
								gap: 5px;

								@media only screen and (max-width: 767px) {
									flex-direction: column;
								}

								.icon-tag-element {
									margin: 0;
								}
							}
						}
					}
				}

				.burgers-page__table-row-not-found {
					display: table-row;
					border-top: solid 1px #d2d2d2;

					.burgers-page__table-cell {
						text-align: center;
						padding: 20px;
						display: table-cell;
					}

					.burgers-page__table-row-not-found__text {
						text-align: center;
						opacity: 0.7;
						width: 1000px;
					}
				}
			}
		}
	}
</style>
